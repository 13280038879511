import React from 'react';
import { connect } from 'react-redux';
import { graphql, Link } from 'gatsby';
import Layout from '../layouts/Index';
import SEO from '../components/SEO';
import Style from '../styles/pages/collaboration.module.scss';
import Image from '../components/Images'

const Collaboration = ({ 
  data: { prismicContentCollaboration, staticText }, 
  pageContext
}) => {
  const staticPrismicTexts = staticText.edges[0].node.data.texts;
  const _t = (str) => staticPrismicTexts[staticPrismicTexts.findIndex(item => item.key_text === str)].text.text;
  const { data } = prismicContentCollaboration;
  let period = '';
  
  // if (data.period === null) {
  //   return (
  //     <Layout 
  //       context={pageContext} 
  //       pageTitle={data.title.text}
  //       _t={staticPrismicTexts}
  //     >
  //       <SEO
  //         pageTitle={data.seo__meta_title_.text} 
  //         pageDescription={data.seo__meta_description_.text} 
  //         context={pageContext}
  //       />
  //       <div className="page collaboration">
  //         <section className="regular">
  //           <div className="container">
  //             <div className="breadcrumb">
  //               <Link to={`/${pageContext.langKey}/`}>Wakatoon</Link>&nbsp;/&nbsp;
  //               <Link to={`/${pageContext.langKey}/collaborations/`}>Collaborations</Link>
  //               &nbsp;/&nbsp;{data.title.text}
  //             </div>
  //           </div>
  //         </section>
  //       </div>
  //     </Layout>
  //   )
  // }

  period = data.period && data.period.split(' ');
  let hideLeftColumn = false;
  hideLeftColumn = (data.stats.length === 0 && data.wakanecdote.text === null);
  
  // const pageId = pageContext.pagesId;
  // const nextPage = (pageId + 1 > (pageContext.pagesList.length - 1) ? 0 : (pageId + 1));
  // const previousPage = (pageId - 1 < 0 ? (pageContext.pagesList.length - 1) : (pageId - 1));
  
  return (
    <Layout 
      context={pageContext} 
      pageTitle={data.title.text}
      _t={staticPrismicTexts}
    >
      <SEO
        pageTitle={data.seo__meta_title_.text} 
        pageDescription={data.seo__meta_description_.text} 
        ogImage={data.meta_image.url}
        context={pageContext}
      />
      <div className="page collaboration">
        <section className="regular">
          <div className="container">
            <div className="breadcrumb">
              <Link to={`/${pageContext.langKey}/`}>Wakatoon</Link>&nbsp;/&nbsp;
              <Link to={`/${pageContext.langKey}/collaborations/`}>Collaborations</Link>
              &nbsp;/&nbsp;{data.title.text}

              {/* <div className="breadcrumb-navigation">
                <Link to={`${pageContext.categoryLink}${pageContext.pagesList[previousPage].item.document[0].uid}/`}>
                  {_t('Précédent')}
                </Link>
                <Link to={`${pageContext.categoryLink}${pageContext.pagesList[nextPage].item.document[0].uid}/`}>
                  {_t('Suivant')}
                </Link>
              </div> */}
            </div>

            <div className="row">
              <div 
                className={`${Style.col4} ${Style.mobileLayout} ${Style.desktop} col-4 no-padding`}
              >
                <div style={{ display: hideLeftColumn ? 'none' : 'block' }}>
                  { data.stats.length > 0 && 
                    <>
                      <div>
                        <p className={Style.statsTitle} style={{ color: data.color }}>
                        {_t('Quelques chiffres')}
                        </p>
                      </div>
                      <div className={Style.stats}>
                        { data.stats.map((item, key) => {
                          return (
                            <div 
                              key={`item-${key}`} 
                              className={`${Style.keyNumbers} ${data.stats.length < 2 ? Style.keyNumbersCentered : ''}`}
                              style={{ backgroundColor: data.color }}
                            >
                              <div>
                                <p>
                                  <span>{item.stats1.text}</span>
                                  {item.caption.text}
                                </p>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </> 
                  }

                  { data.wakanecdote.text && 
                    <div className={Style.wakanecdote}>
                      <p>{_t('Wakanecdote')}</p>
                      <span>{data.wakanecdote.text}</span>
                    </div> 
                  }
                </div>
              </div>

              <div className={`${Style.col8} ${hideLeftColumn ? 'col-12' : 'col-8'}`}>
                <div className={Style.content}>
                  {
                    period ? (
                      <React.Fragment>
                        <div className={Style.period}>
                          <div>
                            <span>{period[0]}</span>
                            {period[1] === '-' ? period[2] : period[1]}
                          </div>
                        </div>
                        <img src={data.page_title_logo.url} alt={data.title.text} />
                        <div className={Style.periodMobile}>
                          <div>
                            <span>{period[0]}</span>
                            {period[1] === '-' ? period[2] : period[1]}
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <img src={data.page_title_logo.url} alt={data.title.text} />
                      </React.Fragment>
                    )
                  }
                  <h1>{data.title.text}</h1>
                  <div dangerouslySetInnerHTML={{ __html: data.description.html}}></div>
                </div>
              </div>
            </div>

            <div className={`${Style.col4} ${Style.mobileLayout} col-4 no-padding`}>
              <div style={{ display: hideLeftColumn ? 'none' : 'block' }}>
                { data.stats.length > 0 && 
                  <>
                    <div>
                      <p 
                        className={Style.statsTitle}
                        style={{ color: data.color }}
                      >
                        {_t('Quelques chiffres')}
                      </p>
                    </div>
                    <div className={Style.stats}>
                      { data.stats.map((item, key) => {
                        return (
                          <div 
                            key={`item-${key}`} 
                            className={`${Style.keyNumbers} ${data.stats.length < 2 ? Style.keyNumbersCentered : ''}`}
                            style={{ backgroundColor: data.color }}
                          >
                            <div>
                              <p>
                                <span>{item.stats1.text}</span>
                                {item.caption.text}
                              </p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </>
                }

                {
                  data.wakanecdote.text && 
                  <div className={Style.wakanecdote}>
                    <p>{_t('Wakanecdote')}</p>
                    <span>{data.wakanecdote.text}</span>
                  </div>
                }
              </div>
            </div>
            
            {
              (data.body && data.body.length > 0) && (
                <div className={`container ${Style.additionalContent}`} style={{paddingTop: 50}}>
                  {
                    data.body.map((i, index) => {
                      return (
                        <div className="row no-gutters" key={`row-${index}`}>
                          <div className="col-12">
                            <h2>{i.primary.content_body_title.text}</h2>
                            <div dangerouslySetInnerHTML={{ __html: i.primary.content_body.html}}></div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              )
            }

            { data.photos.length > 0 &&
              <div className={Style.photosContainer}>
                { data.photos.map((item, key) => {
                  return (
                    <div key={`photo-${key}`} className="row">
                      <div className="col-12 no-padding">
                        {
                          item.photo.url && <Image src={item.photo.url} alt={item.photo.alt} />
                        }
                      </div>
                    </div>
                  )
                })}
              </div>
            }

            { data.videos.length > 0 &&
              <div className={Style.photosContainer}>
                { data.videos[0].embed_link.embed_url && (
                  <React.Fragment>
                    { data.videos.map((item, key) => {
                      const videoLink = item.embed_link.embed_url.split('.com/');
                      const video = `${videoLink[0]}.com/embed/${videoLink[1]}`;
                      return (
                        <div key={`video-${key}`} className="row">
                          <div className="col-12 no-padding">
                            <div className="yt-container">
                              <iframe 
                                title="video"
                                className="yt-video"
                                frameBorder="0" 
                                width="480" 
                                height="270" 
                                src={video} 
                                allow="autoplay">
                              </iframe>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </React.Fragment>
                )}
              </div>
            }
            
          </div>
        </section>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  ...state.locale
});
export default connect(mapStateToProps)(Collaboration);

export const pageQuery = graphql`
  query CollaborationBySlug($uid: String!, $locale: String!) {
    prismicContentCollaboration(uid: { eq: $uid }, lang: {eq: $locale}) {
      lang
      uid
      data {
        seo__meta_title_ {
          text
        }
        seo__meta_description_ {
          text
        }
        title {
          text
        }
        meta_image {
          url
        }
        period
        page_title_logo {
          url
        }
        stats {
          stats1 {
            text
          }
          caption {
            text
          }
        }
        color
        description {
          html
        }
        body {
          primary {
            content_body {
              html
            }
            content_body_title {
              text
            }
          }
        }
        #content {
        #  content_description {
        #    html
        #  }
        #  title1 {
        #    text
        #  }
        #}
        videos {
          embed_link {
            html
            thumbnail_url
            embed_url
            provider_name
            title
          }
        }
        wakanecdote {
          text
        }
        photos {
          photo {
            alt
            url
          }
        }
      }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;